import {
  FullWidthBackgroundContainer,
  GridWithGaps,
} from '@dreamstack/feature-components'
import type { OneColumnLayoutFragment } from '@dreamstack/investors-graphql'
import Image from 'next/legacy/image'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import TailwindConfig from '../../../../tailwind.config'
import { ChildrenBlockSpacingContainer } from '../contentful/ChildrenBlockSpacingContainer'
import { ContentfulBlocks } from '../contentful/ContentfulBlocks'
import type { QueryAllBlocksRecursivelyResult } from '../contentful/QueryAllBlocksRecursivelyResult'
import { getBlockChildren } from './block.utils'

// FALLBACK IF 100vw BREAKS LAYOUT
// const FullWidthBackgroundContainer = styled.div(() => [
//   tw`bg-accentroGray-full`,
//   ` width: calc(100vw - 24px);
//   z-index: -1;
//     position: absolute;
//     right: 50%;
//     left: 50%;
//     margin-left: -50vw;
//     margin-right: -50vw;
//     overflow: hidden;
//     height: calc(100% + 96px);
//     top: -48px;
//     @media (pointer: coarse){
//       width: 100vw;
//     }
//     `,
// ])

const StyledGridWithOptionalImage = styled(GridWithGaps)(
  ({ bgSrc }: { bgSrc?: string }) => [
    tw`relative`,
    // !!bgSrc && tw`background-image[url(${bgSrc})] bg-center bg-cover`,
    !!bgSrc &&
      `@media (min-width: 880px){background-image: url(${bgSrc}); background-position: center; background-size: cover;}`,
    !!bgSrc && tw`py-px-24 md:(py-px-48) lg:(py-px-64)`,
  ]
)

const StyledTextContainer = styled.div(
  ({
    smallVersion,
    addBackgroundPaddings,
    padding,
  }: {
    smallVersion: boolean
    addBackgroundPaddings: boolean
    padding?: string | null
  }) => [
    !smallVersion && tw`col-span-full`,
    smallVersion &&
      tw`bg-accentroGray-full col-span-full md:(p-px-48 col-start-7 col-span-6) lg:(col-start-8 col-span-4)`,
    addBackgroundPaddings && tw`py-px-24 ml:(py-px-48)`,
    padding === 'none' && tw`pb-0!`,
  ]
)

type PossibleColors = {
  AccentroAquaVeryLight: string
  Black: string
}

const possibleColors: PossibleColors = {
  AccentroAquaVeryLight:
    TailwindConfig.theme.extend.colors.accentroAquaVeryLight.full,
  Black: TailwindConfig.theme.extend.colors.accentroGray.full,
}

export const OneColumnLayout: FunctionComponent<
  React.PropsWithChildren<{
    block: QueryAllBlocksRecursivelyResult<OneColumnLayoutFragment>
  }>
> = ({ block }) => {
  const backgroundColor =
    block?.backgroundColor && block?.backgroundColor in possibleColors
      ? possibleColors[block.backgroundColor as keyof PossibleColors]
      : null
  const enableBlackBackground =
    !!block?.enableBlackBackground || block?.backgroundColor === 'black'
  const imageSrc = block?.backgroundImage?.url ?? undefined

  const showBackgroundComponent = enableBlackBackground || !!backgroundColor

  return (
    <>
      <StyledGridWithOptionalImage bgSrc={imageSrc}>
        {showBackgroundComponent && (
          <FullWidthBackgroundContainer backgroundColor={backgroundColor} />
        )}
        <StyledTextContainer
          smallVersion={!!block.smallMainColumnOverImage}
          addBackgroundPaddings={showBackgroundComponent}
          padding={block?.padding}
        >
          <ChildrenBlockSpacingContainer
            withExtraVerticaLPadding={
              !!block?.withExtraPadding || block?.padding === 'extra'
            }
            withExtraSideLPadding={!!imageSrc}
          >
            {!!imageSrc && (
              <div tw="relative w-full h-px-274 md:(hidden)">
                <Image
                  tw="margin[-48px] md:(margin[0])" // negative of additionalSidePadding
                  src={imageSrc}
                  layout={'fill'}
                  objectFit={'cover'}
                  alt={block?.backgroundImage?.description || ''}
                />
              </div>
            )}
            <ContentfulBlocks
              blocks={getBlockChildren(
                block,
                block?.mainColumnCollection?.items
              )}
              // blocks={filter((block as any).childBlockData)}
              customProps={{
                blackBackground:
                  enableBlackBackground || !!block.smallMainColumnOverImage,
              }}
            />
          </ChildrenBlockSpacingContainer>
        </StyledTextContainer>
      </StyledGridWithOptionalImage>
    </>
  )
}
